@-webkit-keyframes wave-very-light {
  0% {
    -webkit-transform: rotate(-0.5deg); }
  50% {
    -webkit-transform: rotate(0.5deg); }
  100% {
    -webkit-transform: rotate(-0.5deg); } }

@keyframes wave-very-light {
  0% {
    transform: rotate(-0.5deg); }
  50% {
    transform: rotate(0.5deg); }
  100% {
    transform: rotate(-0.5deg); } }

@-webkit-keyframes wave-light {
  0% {
    -webkit-transform: rotate(-1deg); }
  50% {
    -webkit-transform: rotate(1deg); }
  100% {
    -webkit-transform: rotate(-1deg); } }

@keyframes wave-light {
  0% {
    transform: rotate(-1deg); }
  50% {
    transform: rotate(1deg); }
  100% {
    transform: rotate(-1deg); } }

@-webkit-keyframes wave {
  0% {
    -webkit-transform: rotate(-3deg); }
  50% {
    -webkit-transform: rotate(3deg); }
  100% {
    -webkit-transform: rotate(-3deg); } }

@keyframes wave {
  0% {
    transform: rotate(-3deg); }
  50% {
    transform: rotate(3deg); }
  100% {
    transform: rotate(-3deg); } }

.pink-bg {
  background-color: #D96392; }

.pink-txt {
  color: #D96392; }

.pink-bkg {
  background: #D96392;
  background-color: #D96392; }

.pink-brd {
  border-color: #D96392; }

div.button.pink {
  background: linear-gradient(to right, rgba(217, 99, 146, 0.15), rgba(255, 255, 255, 0)); }
  div.button.pink a,
  div.button.pink a:after {
    color: #D96392; }

a.pink {
  color: rgba(217, 99, 146, 0.75); }
  a.pink:hover {
    color: #D96392; }

.purple-bg {
  background-color: #9013FE; }

.purple-txt {
  color: #9013FE; }

.purple-bkg {
  background: #9013FE;
  background-color: #9013FE; }

.purple-brd {
  border-color: #9013FE; }

div.button.purple {
  background: linear-gradient(to right, rgba(144, 19, 254, 0.15), rgba(255, 255, 255, 0)); }
  div.button.purple a,
  div.button.purple a:after {
    color: #9013FE; }

a.purple {
  color: rgba(144, 19, 254, 0.75); }
  a.purple:hover {
    color: #9013FE; }

.blue-bg {
  background-color: #00ADCD; }

.blue-txt {
  color: #00ADCD; }

.blue-bkg {
  background: #00ADCD;
  background-color: #00ADCD; }

.blue-brd {
  border-color: #00ADCD; }

div.button.blue {
  background: linear-gradient(to right, rgba(0, 173, 205, 0.15), rgba(255, 255, 255, 0)); }
  div.button.blue a,
  div.button.blue a:after {
    color: #00ADCD; }

a.blue {
  color: rgba(0, 173, 205, 0.75); }
  a.blue:hover {
    color: #00ADCD; }

.sky-bg {
  background-color: #97CCF6; }

.sky-txt {
  color: #97CCF6; }

.sky-bkg {
  background: #97CCF6;
  background-color: #97CCF6; }

.sky-brd {
  border-color: #97CCF6; }

div.button.sky {
  background: linear-gradient(to right, rgba(151, 204, 246, 0.15), rgba(255, 255, 255, 0)); }
  div.button.sky a,
  div.button.sky a:after {
    color: #97CCF6; }

a.sky {
  color: rgba(151, 204, 246, 0.75); }
  a.sky:hover {
    color: #97CCF6; }

.orange-bg {
  background-color: #F76D27; }

.orange-txt {
  color: #F76D27; }

.orange-bkg {
  background: #F76D27;
  background-color: #F76D27; }

.orange-brd {
  border-color: #F76D27; }

div.button.orange {
  background: linear-gradient(to right, rgba(247, 109, 39, 0.15), rgba(255, 255, 255, 0)); }
  div.button.orange a,
  div.button.orange a:after {
    color: #F76D27; }

a.orange {
  color: rgba(247, 109, 39, 0.75); }
  a.orange:hover {
    color: #F76D27; }

.yellow-bg {
  background-color: #F1B500; }

.yellow-txt {
  color: #F1B500; }

.yellow-bkg {
  background: #F1B500;
  background-color: #F1B500; }

.yellow-brd {
  border-color: #F1B500; }

div.button.yellow {
  background: linear-gradient(to right, rgba(241, 181, 0, 0.15), rgba(255, 255, 255, 0)); }
  div.button.yellow a,
  div.button.yellow a:after {
    color: #F1B500; }

a.yellow {
  color: rgba(241, 181, 0, 0.75); }
  a.yellow:hover {
    color: #F1B500; }

.green-bg {
  background-color: #6BA500; }

.green-txt {
  color: #6BA500; }

.green-bkg {
  background: #6BA500;
  background-color: #6BA500; }

.green-brd {
  border-color: #6BA500; }

div.button.green {
  background: linear-gradient(to right, rgba(107, 165, 0, 0.15), rgba(255, 255, 255, 0)); }
  div.button.green a,
  div.button.green a:after {
    color: #6BA500; }

a.green {
  color: rgba(107, 165, 0, 0.75); }
  a.green:hover {
    color: #6BA500; }

.teal-bg {
  background-color: #78B8A0; }

.teal-txt {
  color: #78B8A0; }

.teal-bkg {
  background: #78B8A0;
  background-color: #78B8A0; }

.teal-brd {
  border-color: #78B8A0; }

div.button.teal {
  background: linear-gradient(to right, rgba(120, 184, 160, 0.15), rgba(255, 255, 255, 0)); }
  div.button.teal a,
  div.button.teal a:after {
    color: #78B8A0; }

a.teal {
  color: rgba(120, 184, 160, 0.75); }
  a.teal:hover {
    color: #78B8A0; }

.gray-bg {
  background-color: #444444; }

.gray-txt {
  color: #444444; }

.gray-bkg {
  background: #444444;
  background-color: #444444; }

.gray-brd {
  border-color: #444444; }

div.button.gray {
  background: linear-gradient(to right, rgba(68, 68, 68, 0.15), rgba(255, 255, 255, 0)); }
  div.button.gray a,
  div.button.gray a:after {
    color: #444444; }

a.gray {
  color: rgba(68, 68, 68, 0.75); }
  a.gray:hover {
    color: #444444; }

.light-gray-bg {
  background-color: #999999; }

.light-gray-txt {
  color: #999999; }

.light-gray-bkg {
  background: #999999;
  background-color: #999999; }

.light-gray-brd {
  border-color: #999999; }

div.button.light-gray {
  background: linear-gradient(to right, rgba(153, 153, 153, 0.15), rgba(255, 255, 255, 0)); }
  div.button.light-gray a,
  div.button.light-gray a:after {
    color: #999999; }

a.light-gray {
  color: rgba(153, 153, 153, 0.75); }
  a.light-gray:hover {
    color: #999999; }

.lightest-gray-bg {
  background-color: #fbfbfb; }

.lightest-gray-txt {
  color: #fbfbfb; }

.lightest-gray-bkg {
  background: #fbfbfb;
  background-color: #fbfbfb; }

.lightest-gray-brd {
  border-color: #fbfbfb; }

div.button.lightest-gray {
  background: linear-gradient(to right, rgba(251, 251, 251, 0.15), rgba(255, 255, 255, 0)); }
  div.button.lightest-gray a,
  div.button.lightest-gray a:after {
    color: #fbfbfb; }

a.lightest-gray {
  color: rgba(251, 251, 251, 0.75); }
  a.lightest-gray:hover {
    color: #fbfbfb; }

.white-bg {
  background-color: white; }

.white-txt {
  color: white; }

.white-bkg {
  background: white;
  background-color: white; }

.white-brd {
  border-color: white; }

div.button.white {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)); }
  div.button.white a,
  div.button.white a:after {
    color: white; }

a.white {
  color: rgba(255, 255, 255, 0.75); }
  a.white:hover {
    color: white; }

.black-bg {
  background-color: black; }

.black-txt {
  color: black; }

.black-bkg {
  background: black;
  background-color: black; }

.black-brd {
  border-color: black; }

div.button.black {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0)); }
  div.button.black a,
  div.button.black a:after {
    color: black; }

a.black {
  color: rgba(0, 0, 0, 0.75); }
  a.black:hover {
    color: black; }

.redorange-bg {
  background-color: #EE4A00; }

.redorange-txt {
  color: #EE4A00; }

.redorange-bkg {
  background: #EE4A00;
  background-color: #EE4A00; }

.redorange-brd {
  border-color: #EE4A00; }

div.button.redorange {
  background: linear-gradient(to right, rgba(238, 74, 0, 0.15), rgba(255, 255, 255, 0)); }
  div.button.redorange a,
  div.button.redorange a:after {
    color: #EE4A00; }

a.redorange {
  color: rgba(238, 74, 0, 0.75); }
  a.redorange:hover {
    color: #EE4A00; }

.magenta-bg {
  background-color: #bd10e0; }

.magenta-txt {
  color: #bd10e0; }

.magenta-bkg {
  background: #bd10e0;
  background-color: #bd10e0; }

.magenta-brd {
  border-color: #bd10e0; }

div.button.magenta {
  background: linear-gradient(to right, rgba(189, 16, 224, 0.15), rgba(255, 255, 255, 0)); }
  div.button.magenta a,
  div.button.magenta a:after {
    color: #bd10e0; }

a.magenta {
  color: rgba(189, 16, 224, 0.75); }
  a.magenta:hover {
    color: #bd10e0; }

body {
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-size: 21px;
  line-height: 1.619;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased; }

small {
  font-size: 15px;
  line-height: 1.62;
  display: block; }

h1 {
  font-size: 140px;
  line-height: 0.96;
  margin-bottom: 77px;
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-weight: bold; }

h2 {
  font-size: 40px;
  line-height: 45px;
  margin-bottom: 20px;
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400; }
  @media screen and (min-width: 768px) {
    h2 {
      font-size: 80px;
      line-height: 1;
      margin-bottom: 30px; } }
  @media screen and (min-width: 960px) {
    h2 {
      font-size: 96px;
      line-height: .91;
      margin-bottom: 30px; } }

h3 {
  font-size: 64px;
  line-height: 0.984;
  margin-top: 76px;
  margin-bottom: 76px;
  font-family: "clarendon-urw", serif;
  font-style: normal;
  font-weight: 100; }
  h3.intro {
    margin-top: 0;
    font-size: 48px;
    line-height: 1.08; }
  h3.quote {
    font-style: normal;
    font-weight: 100;
    font-size: 25px;
    line-height: 1.2; }
    @media screen and (min-width: 768px) {
      h3.quote {
        font-size: 40px; } }

h4.sub {
  font-weight: lighter;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-size: 22px;
  line-height: 1; }
  @media screen and (min-width: 768px) {
    h4.sub {
      font-size: 36px; } }

h5 {
  font-size: 21px;
  line-height: 1.4;
  margin-bottom: 30px;
  margin-top: 30px;
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-weight: bold; }
  h5.uppercase-wide-spacing {
    font-weight: 700;
    font-size: 12px;
    margin: 40px 0; }
    @media screen and (min-width: 375px) {
      h5.uppercase-wide-spacing {
        font-size: 14px; } }
    @media screen and (min-width: 1024px) {
      h5.uppercase-wide-spacing {
        font-size: 21px; } }

.uppercase-wide-spacing {
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  letter-spacing: 5px;
  text-transform: uppercase;
  text-align: center;
  display: block;
  position: relative; }
  @media screen and (min-width: 1024px) {
    .uppercase-wide-spacing {
      text-align: left;
      font-size: 21px; } }

p {
  max-width: 100%;
  margin-bottom: 19px; }
  p:last-child {
    margin-bottom: 0; }
  @media screen and (min-width: 768px) {
    p {
      margin-bottom: calc(21px * 1.619); } }

a {
  color: rgba(68, 68, 68, 0.6);
  text-decoration: none; }
  a:hover {
    color: #444444; }

sup {
  font-size: 60%; }

strong, b, .bold {
  font-weight: bold; }

.normal {
  font-weight: normal; }

.lighter {
  font-weight: lighter; }

.lightest {
  font-weight: 100; }

em, .italic, i:not(.fa) {
  font-style: italic; }

i.fab {
  font-style: normal !important; }

.uppercase {
  text-transform: uppercase; }

.footnote {
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 3.3px;
  line-height: 1.43;
  margin: 7px 0; }

.date {
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  letter-spacing: .2rem; }

.black5 {
  font-size: 18px;
  line-height: 1.619;
  margin-bottom: 0px;
  margin-top: 30px;
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .4rem; }
  @media screen and (min-width: 960px) {
    .black5 {
      font-size: 21px; } }

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  color: white;
  font-family: "clarendon-urw", serif;
  font-style: normal;
  font-weight: 100;
  font-size: 21px;
  font-style: normal;
  font-weight: 100;
  border: 3px solid white;
  border-radius: 10px;
  line-height: unset; }

.btn:hover {
  border: 3px solid transparent;
  color: black;
  background-color: white; }

.overlay {
  position: relative; }
  .overlay:before {
    content: '';
    display: block;
    opacity: .8;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; }
  .overlay .content-width, .overlay .content-width-large {
    position: relative;
    z-index: 2; }
  .overlay.redorange:before {
    background-color: #EE4A00; }
  .overlay.orange:before {
    background-color: #F76D27; }
  .overlay.pink:before {
    background-color: #D96392; }
  .overlay.green:before {
    background-color: #6BA500; }
  .overlay.yellow:before {
    background-color: #F1B500; }
  .overlay.blue:before {
    background-color: #00ADCD; }
  .overlay.sky:before {
    background-color: #97CCF6; }
  .overlay.purple:before {
    background-color: #9013FE; }
  .overlay.pink:before {
    background-color: #D96392; }

.table {
  display: table;
  width: 100%;
  padding-top: 2rem;
  font-size: 4vw; }
  .table .nbsp {
    display: none; }
  @media screen and (min-width: 768px) {
    .table {
      font-size: 21px; }
      .table .nbsp {
        display: inline; } }
  .table .table-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center; }
    .table .table-row .arrow-spacer {
      width: 100%;
      background: #9013FE;
      height: 1px;
      position: relative;
      transform: translateY(-1px); }
      .table .table-row .arrow-spacer:after {
        content: '';
        background-image: url("/public/images/arrow-head-purple.svg");
        width: 11px;
        height: 11px;
        position: absolute;
        right: -3px;
        z-index: 1;
        display: block;
        background-repeat: no-repeat;
        top: -5px;
        transform: rotate(-90deg); }
    .table .table-row .cell {
      display: inline-block;
      text-transform: uppercase;
      font-family: 'Alte Haas Grotesk', 'sans-serif';
      font-style: normal;
      font-weight: 400;
      letter-spacing: .2rem;
      font-weight: bold;
      padding-right: 10px; }
      .table .table-row .cell.right {
        padding-right: 0;
        padding-left: 14px; }

* {
  box-sizing: border-box; }

html {
  width: 100%;
  position: relative;
  overflow-x: hidden; }

body {
  margin: 0;
  display: none;
  background-color: #000; }
  body.loaded {
    display: block; }

img {
  max-width: 100%;
  opacity: 1;
  transition: opacity 0.3s; }

img[data-src] {
  opacity: 0; }

body.scroll-locked {
  overflow: hidden;
  height: 100%; }

h1, h2, h3, h4, h5, h6, p, ul {
  margin: 0;
  padding: 0; }

li {
  list-style: none; }

li > ul {
  margin-left: 1rem; }

body {
  font-family: "clarendon-urw", serif;
  font-style: normal;
  font-weight: 100;
  font-size: 21px;
  line-height: 1.619; }

section {
  max-width: 100%;
  overflow: hidden; }

h1 {
  font-size: 40px;
  line-height: 0.96;
  margin-bottom: 35px;
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-weight: bold; }
  @media screen and (min-width: 375px) {
    h1 {
      font-size: 50px; } }
  @media screen and (min-width: 768px) {
    h1 {
      font-size: 90px;
      margin-bottom: 36px; } }
  @media screen and (min-width: 1024px) {
    h1 {
      font-size: 104px;
      margin-bottom: 36px; } }

h2 {
  font-size: 40px;
  line-height: 45px;
  margin-bottom: 20px;
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400; }
  @media screen and (min-width: 768px) {
    h2 {
      font-size: 80px;
      line-height: 1;
      margin-bottom: 30px; } }
  @media screen and (min-width: 960px) {
    h2 {
      font-size: 96px;
      line-height: .91;
      margin-bottom: 30px; } }

h3 {
  font-size: 32px;
  line-height: 1.1;
  margin-top: 35px;
  margin-bottom: 35px;
  font-family: "clarendon-urw", serif;
  font-style: normal;
  font-weight: 100; }
  @media screen and (min-width: 768px) {
    h3 {
      font-size: 64px;
      margin-top: 76px;
      margin-bottom: 76px;
      line-height: 0.984; } }
  h3.intro {
    margin-top: 0;
    font-size: 48px;
    line-height: 1.08; }

h5 {
  font-size: 15px;
  line-height: 1.2;
  margin-bottom: 30px;
  margin-top: 30px;
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-weight: bold; }
  @media screen and (min-width: 768px) {
    h5 {
      line-height: 1.4;
      font-size: 21px; } }

p {
  margin-bottom: calc(21px * 1.619); }

p:last-child {
  margin-bottom: 0; }

a {
  color: rgba(68, 68, 68, 0.7);
  text-decoration: underline; }
  a.no-deco {
    text-decoration: none; }
  a:hover {
    color: #444444; }
  a.main-nav {
    text-decoration: none; }

.content-sections .header a, .pull-quote a, .footer a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: underline; }

.content-sections .header a:hover, .pull-quote a:hover, .footer a:hover {
  color: white; }

sup {
  font-size: 60%; }

strong, b, .bold {
  font-weight: bold; }

em, .italic, i:not(.fa) {
  font-style: italic; }

.center {
  text-align: center; }

.right {
  text-align: right; }

.left {
  text-align: left; }

.margin-bottom {
  margin-bottom: 132px; }

.margin-bottom-small {
  margin-bottom: 66px; }

.margin-top {
  margin-top: 132px; }

.margin-top-small {
  margin-top: 66px; }

.no-margin-bottom {
  margin-bottom: 0 !important; }

.no-margin-top {
  margin-top: 0 !important; }

.no-margin {
  margin: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important; }

.padding-bottom {
  padding-bottom: 2rem !important; }
  @media screen and (min-width: 768px) {
    .padding-bottom {
      padding-bottom: 132px !important; } }

.padding-top {
  padding-top: 2rem !important; }
  @media screen and (min-width: 768px) {
    .padding-top {
      padding-top: 132px !important; } }

@media screen and (min-width: 768px) {
  .padding-right {
    padding: 0 13% 0 0; }
  .padding-right-medium {
    padding: 0 28% 0 0; }
  .padding-right-big {
    padding: 0 40% 0 0; } }

.padding-left {
  padding: 0 0 0 10%; }

.padding-left-medium {
  padding: 0 0 0 30%; }

.padding-left-big {
  padding: 0 0 0 40%; }

.padding-x {
  padding: 0 10% 0 10%; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

@media all and (max-width: 767px) {
  .no-padding-top-mobile {
    padding-top: 0 !important; } }

.no-padding {
  padding: 0 !important; }

.content-width {
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
  padding: 0 6%;
  padding-top: 2rem;
  padding-bottom: 2rem; }
  @media screen and (min-width: 768px) {
    .content-width {
      padding: 0 10%; } }
  @media screen and (min-width: 960px) {
    .content-width {
      padding: 0; } }
  @media screen and (min-width: 768px) {
    .content-width {
      padding-top: 0;
      padding-bottom: 0; } }

@media all and (max-width: 767px) {
  .content-width + .content-width {
    padding-top: 0 !important; } }

@media all and (max-width: 767px) {
  .content-width > h3 {
    padding-top: 0 !important; } }

.content-width-medium, .content-width-large {
  width: 100%;
  margin: 0 auto;
  padding: 0 10%;
  padding-top: 2rem;
  padding-bottom: 2rem; }
  @media screen and (min-width: 768px) {
    .content-width-medium, .content-width-large {
      padding: 0 82px; } }
  @media screen and (min-width: 768px) {
    .content-width-medium, .content-width-large {
      padding-top: 0;
      padding-bottom: 0; } }
  .content-width-medium.no-max, .content-width-large.no-max {
    max-width: 100vw; }

.content-width-medium {
  max-width: 1280px; }

.content-width-large {
  max-width: 1440px; }

.content-width-full {
  position: relative;
  width: 100%;
  margin: 0 auto; }

.content-sections {
  background-color: white;
  color: black; }

.content-sections img.full-width {
  width: 100%; }

.content-sections .pull-quote {
  width: 100%;
  min-height: 400px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 10% 0;
  font-size: 40px;
  line-height: 1.083;
  color: white;
  padding-top: 2rem;
  padding-bottom: 2rem; }
  @media all and (max-width: 767px) {
    .content-sections .pull-quote.small-mobile {
      font-size: 1.5rem;
      line-height: 1.4; } }
  @media screen and (min-width: 768px) {
    .content-sections .pull-quote {
      margin: 132px 0; } }
  @media screen and (min-width: 960px) {
    .content-sections .pull-quote {
      min-height: auto;
      min-height: 50vh; } }
  @media screen and (min-width: 768px) {
    .content-sections .pull-quote {
      padding-top: 0;
      padding-bottom: 0; } }
  .content-sections .pull-quote.callout {
    font-size: 40px;
    line-height: 1.083; }
    @media screen and (min-width: 768px) {
      .content-sections .pull-quote.callout {
        font-size: 80px; } }
    .content-sections .pull-quote.callout h4 {
      font-family: 'Alte Haas Grotesk', 'sans-serif';
      font-style: normal;
      font-weight: 400;
      font-weight: bold; }

.content-sections .inner-width {
  max-width: 700px;
  width: 100%;
  margin: 0 auto; }

.content-sections .header {
  width: 100%;
  min-height: 700px;
  color: white;
  padding: 160px 0 127px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  @media screen and (min-width: 960px) {
    .content-sections .header {
      min-height: auto;
      height: 100vh; } }

.content-sections .header h3.intro {
  max-width: 1100px; }

.content-sections .content-section {
  margin-bottom: 130px; }

@media screen and (min-width: 768px) {
  .content-sections section > .content-width {
    margin-top: 82px; } }

.fade-white {
  position: relative;
  min-height: 400px; }
  @media screen and (min-width: 960px) {
    .fade-white {
      min-height: 55vh; } }
  .fade-white:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    z-index: 1;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(top, white 0%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 70%, white 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, white 0%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 70%, white 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 70%, white 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0 );
    /* IE6-9 */ }

.recommendation h5 {
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .4rem; }

.recommendation h3 {
  margin-top: 35px;
  margin-bottom: 65px; }

ul.styled-list {
  margin-bottom: 29px; }
  ul.styled-list li {
    list-style: initial;
    margin-left: 25px;
    padding: 0 0 11px;
    margin-bottom: 0; }
  ul.styled-list ul {
    margin-left: 25px; }
    ul.styled-list ul ul {
      magin-left: 25px; }

.card {
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  padding: 9%;
  min-width: 45%;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-weight: bold; }
  @media all and (max-width: 767px) {
    .card {
      width: 100%; } }
  .card p {
    text-align: center; }
    .card p span {
      display: block; }

.video-contain {
  margin: 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }

.vimeo-container {
  box-shadow: none;
  text-align: center;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  flex: 0 0 100%;
  width: 100%;
  min-height: 1px;
  padding: 0; }
  .vimeo-container:after {
    content: "";
    display: block;
    padding-bottom: 56.25%; }
  .vimeo-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.fw-vimeo-vid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.flex {
  display: flex;
  flex: 1; }
  .flex.columns {
    flex-direction: column; }
  .flex.center-vertical .row {
    display: flex;
    align-items: center; }
  .flex .row {
    flex-basis: 100%; }

.mobile-space {
  display: inline; }

@media screen and (min-width: 768px) {
  .mobile-space {
    display: none; } }

.cursor-normal:hover {
  cursor: default; }

.team-members {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-around; }
  @media screen and (min-width: 768px) {
    .team-members {
      width: calc(100% + 48px); } }
  .team-members li > a {
    text-decoration: none; }
  .team-members .image-wrapper {
    max-width: 282px;
    margin: 0 auto; }
  .team-members .no-link .image:after {
    display: none;
    visibility: hidden; }
  .team-members .image {
    max-width: 350px;
    width: 100%;
    padding: 50%;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    position: relative;
    max-width: 282px;
    max-height: 282px; }
    .team-members .image:after {
      content: "+";
      position: absolute;
      top: 0;
      right: 0;
      font-size: 36px;
      width: 38px;
      height: 38px;
      background-color: #6BA500;
      border-radius: 50%;
      line-height: 35px;
      color: white; }
  .team-members.orange .image:after {
    background-color: #F76D27; }
  .team-members li {
    width: calc(70%);
    margin-bottom: 100px;
    cursor: pointer; }
    @media screen and (min-width: 768px) {
      .team-members li {
        width: calc(50%); } }
  .team-members a {
    text-decoration: none; }
  .team-members h3 {
    font-size: 24px;
    line-height: 1.113;
    margin-bottom: 0;
    margin-top: 20px; }
    @media screen and (min-width: 768px) {
      .team-members h3 {
        font-size: 48px; } }
  .team-members h5 {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.5);
    margin: 5px;
    text-align: center; }

@media screen and (min-width: 768px) {
  .case-studies h3 {
    font-size: 44px; } }

.time-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .time-line .card {
    cursor: pointer; }
    .time-line .card:before {
      content: "+";
      position: absolute;
      top: -18px;
      right: -18px;
      font-size: 36px;
      width: 38px;
      height: 38px;
      background-color: #6BA500;
      border-radius: 50%;
      line-height: 35px;
      color: white;
      text-align: center;
      vertical-align: middle;
      font-family: "clarendon-urw", serif;
      font-style: normal;
      font-weight: 100; }
    .time-line .card:after {
      content: '';
      display: block;
      width: 100%;
      border: none;
      height: 3rem;
      bottom: -3rem;
      left: 0;
      padding: 0;
      font-size: 0;
      background-color: #6BA500;
      position: absolute; }
      @media screen and (min-width: 768px) {
        .time-line .card:after {
          height: 5rem;
          bottom: -5rem; } }
    .time-line .card:last-child:after {
      display: none; }

.side-by-side {
  display: flex;
  padding-bottom: 4rem;
  padding-top: 2rem;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media screen and (min-width: 768px) {
    .side-by-side > * {
      width: 45%; } }
  .side-by-side.image-text a {
    width: 100%; }
  .side-by-side .vertical {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

.spotlight {
  align-items: center;
  flex-wrap: wrap; }
  .spotlight .image {
    width: 100%; }
    @media screen and (min-width: 768px) {
      .spotlight .image {
        width: 30%; } }
  .spotlight .text {
    width: 100%; }
    @media screen and (min-width: 768px) {
      .spotlight .text {
        width: 60%; } }
    .spotlight .text h3 {
      font-size: 40px; }
    .spotlight .text a {
      text-decoration: underline; }

.image-text .image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding-bottom: 2rem; }
  @media screen and (min-width: 768px) {
    .image-text .image {
      width: 45%;
      padding-bottom: 0; } }
  .image-text .image a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative; }
  .image-text .image img {
    max-width: 282px;
    width: 80%; }
  .image-text .image:after {
    content: "+";
    font-family: "clarendon-urw", serif;
    font-style: normal;
    font-weight: 100;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 36px;
    width: 38px;
    height: 38px;
    background-color: #6BA500;
    border-radius: 50%;
    line-height: 35px;
    color: white;
    text-align: center;
    vertical-align: middle;
    pointer-events: none; }
  .image-text .image .title {
    font-size: 48px;
    text-align: center;
    line-height: 1;
    padding-bottom: 1rem;
    padding-top: 1rem; }
  .image-text .image .byline {
    font-family: 'Alte Haas Grotesk', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    color: #999999;
    font-size: 21px;
    letter-spacing: .4rem;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.4;
    max-width: 100%; }

.image-text .text {
  width: auto; }
  @media screen and (min-width: 768px) {
    .image-text .text {
      width: 45%; } }

.accordion {
  margin: 75px 0 125px; }

.accordion li {
  margin-bottom: 28px; }

.accordion li.active {
  margin-bottom: 55px; }

.accordion li.active .heading + .accordion-content {
  display: block; }

.accordion li.active .symbol.on {
  display: block; }

.accordion li.active .symbol.off {
  display: none; }

.accordion li.active .icon {
  line-height: 30px; }

.accordion li p {
  margin-top: 38px; }

.accordion .heading {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.accordion .heading .symbol.on {
  display: none; }

.accordion .heading h4 {
  width: calc(100% - 20%);
  font-size: 22px;
  line-height: 1; }
  @media screen and (min-width: 768px) {
    .accordion .heading h4 {
      font-size: 36px; } }

.accordion .heading + .accordion-content {
  display: none; }

.accordion .icon {
  font-size: 36px;
  width: 38px;
  height: 38px;
  text-align: center;
  background-color: #F76D27;
  border-radius: 50%;
  line-height: 35px;
  color: white; }

.number-list-number {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #fff;
  padding-bottom: 8px; }
  @media screen and (min-width: 768px) {
    .number-list-number {
      width: 77px;
      height: 77px; } }

.quote-module {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 60px 0 30px; }
  @media screen and (min-width: 1024px) {
    .quote-module {
      flex-direction: row; } }
  .quote-module .quote-image {
    width: 86%;
    position: relative;
    display: block;
    margin: 0 auto;
    padding-bottom: 20px; }
    @media screen and (min-width: 1024px) {
      .quote-module .quote-image {
        min-width: 315px;
        width: 315px;
        height: 480px;
        margin: 0 0; } }
    .quote-module .quote-image img {
      border-radius: 50%;
      margin: 0 auto;
      display: block; }
      @media screen and (min-width: 1024px) {
        .quote-module .quote-image img {
          height: 315px; } }
    .quote-module .quote-image .quote-control {
      position: absolute;
      right: 24px;
      bottom: 11px; }
      @media screen and (min-width: 1024px) {
        .quote-module .quote-image .quote-control {
          right: 0px;
          bottom: 40px; } }
      .quote-module .quote-image .quote-control .quote-play {
        color: white;
        background-color: #6BA500;
        width: 115px;
        height: 115px;
        border-radius: 115px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
        cursor: pointer; }
        .quote-module .quote-image .quote-control .quote-play i {
          font-size: 40px; }
      .quote-module .quote-image .quote-control .quote-length {
        text-align: center;
        font-family: 'Alte Haas Grotesk', 'sans-serif';
        font-style: normal;
        font-weight: 400;
        font-size: 21px;
        padding-top: 20px;
        letter-spacing: 5px;
        font-weight: 700; }
  .quote-module.audio .quote-image {
    padding-bottom: 90px; }
  .quote-module .quote-text {
    position: relative;
    margin: 0;
    display: block; }
    @media screen and (min-width: 1024px) {
      .quote-module .quote-text {
        margin: 0;
        width: 61%; } }
    .quote-module .quote-text p {
      color: #6BA500;
      font-family: "clarendon-urw", serif;
      font-style: normal;
      font-weight: 100;
      font-size: 25px;
      line-height: 1.2; }
      @media screen and (min-width: 1024px) {
        .quote-module .quote-text p {
          font-size: 40px; } }
  .quote-module .quote-name {
    font-family: 'Alte Haas Grotesk', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 5px;
    text-transform: uppercase;
    text-align: center;
    margin: 40px 0;
    display: block;
    position: relative; }
    @media screen and (min-width: 1024px) {
      .quote-module .quote-name {
        text-align: left;
        font-size: 21px; } }

.row-cards {
  display: flex; }
  @media all and (max-width: 767px) {
    .row-cards {
      font-size: 9px;
      right: 14px;
      left: auto;
      position: relative; } }
  @media all and (max-width: 1023px) {
    .row-cards {
      font-size: 12px; } }
  .row-cards .card {
    padding: 4%; }
    @media all and (max-width: 767px) {
      .row-cards .card {
        margin: 2%; } }
    .row-cards .card:after {
      content: '';
      display: block;
      width: 3rem;
      right: -3rem;
      border: none;
      height: 1px;
      padding: 0;
      font-size: 0;
      background-color: #6BA500;
      position: absolute; }
      @media all and (max-width: 767px) {
        .row-cards .card:after {
          width: 17px;
          right: -17px; } }
    .row-cards .card:last-child:after {
      display: none; }
  .row-cards.three .card {
    width: 32%;
    min-width: 32%; }

.grid-cards h5, .grid-titles h5 {
  font-size: 1.5vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  max-width: 100%;
  line-height: 1.6; }
  @media screen and (min-width: 768px) {
    .grid-cards h5, .grid-titles h5 {
      letter-spacing: 5px;
      line-height: 1.2; } }
  @media screen and (min-width: 1024px) {
    .grid-cards h5, .grid-titles h5 {
      font-size: 21px; } }

.grid-cards .grid-card, .grid-titles .grid-card {
  border-width: 1px;
  border-style: solid;
  max-width: calc((100% - 28px) / 3); }
  @media screen and (min-width: 768px) {
    .grid-cards .grid-card, .grid-titles .grid-card {
      max-width: calc((100% - 84px) / 3); } }

.grid-cards.orange .grid-card, .grid-cards.orange .grid-title, .grid-titles.orange .grid-card, .grid-titles.orange .grid-title {
  margin: 0 7px; }
  @media screen and (min-width: 768px) {
    .grid-cards.orange .grid-card, .grid-cards.orange .grid-title, .grid-titles.orange .grid-card, .grid-titles.orange .grid-title {
      margin: 0 21px; } }

.grid-cards.orange .grid-card, .grid-titles.orange .grid-card {
  border-color: #F76D27;
  padding: 0px 8px;
  position: relative; }
  @media screen and (min-width: 768px) {
    .grid-cards.orange .grid-card, .grid-titles.orange .grid-card {
      padding: 32px 32px; } }

.grid-cards.orange div.grid-card:first-child, .grid-titles.orange div.grid-card:first-child {
  margin-left: 0; }

.grid-cards.orange div.grid-card:last-child, .grid-titles.orange div.grid-card:last-child {
  margin-right: 0;
  background: #F76D27; }
  .grid-cards.orange div.grid-card:last-child h5, .grid-titles.orange div.grid-card:last-child h5 {
    color: white; }

.grid-cards.orange div.grid-card:not(:last-child):after, .grid-titles.orange div.grid-card:not(:last-child):after {
  content: '';
  background-color: transparent;
  background-image: url("/public/images/arrow-orange.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  width: 36px;
  position: absolute;
  top: calc(50% - 7.5%);
  right: -22px;
  border: none;
  height: 15%;
  transform: rotate(-90deg);
  z-index: 5;
  background-size: 30%; }

@media screen and (min-width: 768px) {
  .grid-cards.orange div.grid-card:not(:last-child), .grid-titles.orange div.grid-card:not(:last-child) {
    right: -10px; } }

.grid-cards {
  margin-bottom: 42px; }

.grid-titles h5 {
  color: #F76D27; }

.screen-comp-modals {
  position: relative;
  min-height: 500px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; }
  @media screen and (min-width: 960px) {
    .screen-comp-modals {
      height: 57vh;
      align-items: flex-end;
      justify-content: flex-start; } }
  .screen-comp-modals .comp-target {
    position: relative;
    z-index: 2;
    cursor: pointer; }
    .screen-comp-modals .comp-target:after {
      content: "+";
      position: absolute;
      top: -15px;
      right: 1px;
      font-size: 36px;
      width: 38px;
      height: 38px;
      background-color: #6BA500;
      border-radius: 50%;
      line-height: 35px;
      color: white; }
    @media all and (max-width: 959px) {
      .screen-comp-modals .comp-target {
        margin: 20px 0; } }
    .screen-comp-modals .comp-target.comps-1:after {
      top: -10px;
      right: 0px; }
    @media all and (max-width: 959px) {
      .screen-comp-modals .comp-target.comps-1 {
        width: 50%; } }
    .screen-comp-modals .comp-target.comps-2:after {
      top: -9px;
      right: 16px; }
    .screen-comp-modals .comp-target.comps-3:after {
      top: -16px;
      right: -5px; }
    .screen-comp-modals .comp-target.comps-5:after {
      top: -8px;
      right: -1px; }
    @media all and (max-width: 959px) {
      .screen-comp-modals .comp-target.comps-5 {
        width: 80%; } }
    @media screen and (min-width: 960px) {
      .screen-comp-modals .comp-target {
        position: absolute; }
        .screen-comp-modals .comp-target.comps-1 {
          width: 10%;
          z-index: 5;
          left: 23%;
          bottom: -52px; }
          .screen-comp-modals .comp-target.comps-1:after {
            left: 0;
            right: auto; }
        .screen-comp-modals .comp-target.comps-2 {
          width: 43%;
          z-index: 4;
          right: 1%;
          bottom: -12px; }
          .screen-comp-modals .comp-target.comps-2:after {
            left: 46px;
            right: auto; }
        .screen-comp-modals .comp-target.comps-3 {
          width: 49%;
          z-index: 3;
          left: 22%;
          bottom: -17px; }
          .screen-comp-modals .comp-target.comps-3:after {
            right: 13px; }
        .screen-comp-modals .comp-target.comps-5 {
          width: 26%;
          z-index: 3;
          left: 3%;
          bottom: -8px; } }

.modals.comps .modal .modal-image {
  align-items: flex-start; }
  .modals.comps .modal .modal-image img {
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.7); }
    @media screen and (min-width: 960px) {
      .modals.comps .modal .modal-image img.mobile-img {
        max-width: 26%; } }

.modals .overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 100; }
  .modals .overlay.display {
    display: block;
    background-image: url("/public/images/Hash-Pattern.png");
    background-position: center;
    background-size: cover;
    opacity: .3;
    height: 100vh;
    width: 100vw; }

.modals .modal {
  z-index: 101;
  position: fixed;
  width: 90vw;
  height: 88vh;
  top: 9vh;
  bottom: 3vh;
  left: 5vw;
  right: 5vw;
  background-color: white;
  display: none;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 20px; }
  @media all and (min-width: 768px) {
    .modals .modal {
      top: 7.5vh;
      bottom: 7.5vh;
      left: 5.5vw;
      right: 7.5vw; } }
  .modals .modal.fluid {
    height: auto;
    max-height: 88vh;
    bottom: unset; }
    @media all and (min-width: 768px) {
      .modals .modal.fluid {
        bottom: unset;
        max-height: 85vh; } }
  .modals .modal.active {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .modals .modal .modal-image {
    height: 100%;
    width: 100%;
    overflow: scroll;
    display: flex;
    align-items: center;
    justify-content: center; }
  .modals .modal .close {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #9013FE;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px; }
    @media all and (min-width: 768px) {
      .modals .modal .close {
        top: 50px;
        right: 50px;
        width: 75px;
        height: 75px; } }
    .modals .modal .close:before {
      content: "+";
      position: absolute;
      font-size: 35px;
      width: 35px;
      height: 35px;
      line-height: 32px;
      color: white;
      font-family: "clarendon-urw", serif;
      font-style: normal;
      font-weight: 100; }
      @media all and (min-width: 768px) {
        .modals .modal .close:before {
          top: -5px;
          right: -9px;
          font-size: 75px;
          width: 95px;
          height: 95px;
          line-height: 75px; } }
  .modals .modal.orange .close {
    background-color: #F76D27; }
  .modals .modal .black5 {
    margin-top: 10px; }
  .modals .modal .title {
    font-family: "clarendon-urw", serif;
    font-style: normal;
    font-weight: 100;
    color: #9013FE;
    position: relative;
    padding: 20px 0;
    font-size: 45px;
    left: 0;
    line-height: 1.2; }
    @media all and (min-width: 960px) {
      .modals .modal .title {
        font-size: 64px; } }
    @media all and (min-width: 1024px) {
      .modals .modal .title {
        left: -60px; } }
  .modals .modal .deliverables {
    margin-top: 50px;
    font-family: 'Alte Haas Grotesk', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 5px; }
  @media all and (min-width: 768px) {
    .modals .modal ul {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2; } }
  .modals .modal ul li {
    color: #9013FE;
    font-weight: 500;
    padding: 10px 0;
    line-height: 1.3;
    padding-right: 4%; }

.modals:not(.comps) .modal.active {
  padding: 10vh 20px; }

.taglines h3 {
  color: white !important; }

.content-sections .an-inside-look .header {
  background-color: #EE4A00; }

.content-sections .an-inside-look .pull-quote {
  background-color: #fff; }

.content-sections .an-inside-look img.full-width {
  margin-top: 82px; }

.content-sections .an-inside-look img.full-width.no-top-margin {
  margin-top: 0; }

.content-sections .an-inside-look h3:not(.intro) {
  color: #EE4A00; }

.content-sections .an-inside-look .marketing-competencies {
  width: calc(100% + 50px);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.content-sections .an-inside-look .marketing-competencies h4 {
  font-size: 24px;
  line-height: 1.33;
  color: #EE4A00; }

.content-sections .an-inside-look .marketing-competencies > li {
  width: calc(50% - 50px);
  margin-right: 50px;
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 1.75; }

.content-sections .paradowski .header,
.content-sections .paradowski .pull-quote {
  background-color: #00ADCD; }

.content-sections .paradowski h3:not(.intro) {
  color: #00ADCD; }

.content-sections .paradowski .marketing-competencies h4 {
  font-size: 24px;
  line-height: 1.33;
  color: #00ADCD; }

.content-sections .our-experience .header,
.content-sections .our-experience .pull-quote {
  background-color: #9013FE; }

.content-sections .our-experience h3:not(.intro) {
  color: #9013FE; }

.content-sections .our-experience .marketing-competencies h4 {
  font-size: 24px;
  line-height: 1.33;
  color: #9013FE; }

.content-sections .our-experience .team-members .image:after {
  background-color: #9013FE; }

.content-sections .time-budget .header,
.content-sections .time-budget .pull-quote {
  background-color: #9013FE; }

.content-sections .time-budget h3:not(.intro) {
  color: #9013FE; }

.content-sections .time-budget .marketing-competencies h4 {
  font-size: 24px;
  line-height: 1.33;
  color: #9013FE; }

.content-sections .section-our-approach .header,
.content-sections .section-our-approach .pull-quote {
  background-color: #F1B500; }

.content-sections .section-our-approach h3:not(.intro) {
  color: #F1B500; }

.content-sections .section-our-approach .marketing-competencies h4 {
  font-size: 24px;
  line-height: 1.33;
  color: #F1B500; }

.content-sections .section-time-budget .header,
.content-sections .section-time-budget .pull-quote {
  background-color: #9013FE; }

.content-sections .section-time-budget h3:not(.intro) {
  color: #9013FE; }

.content-sections .section-time-budget .marketing-competencies h4 {
  font-size: 24px;
  line-height: 1.33;
  color: #9013FE; }

.content-sections .section-our-recommendation .header,
.content-sections .section-our-recommendation .pull-quote {
  background-color: #F76D27; }

.content-sections .section-our-recommendation h3:not(.intro) {
  color: #F76D27; }

.content-sections .section-our-recommendation h4:not(.intro) {
  color: #F76D27; }

.content-sections .section-our-recommendation .marketing-competencies h4 {
  font-size: 24px;
  line-height: 1.33;
  color: #F76D27; }

.content-sections .approach-perspective .header,
.content-sections .approach-perspective .pull-quote {
  background-color: #FECC02; }

.content-sections .approach-perspective p a:hover {
  opacity: 0.8; }

.content-sections .approach-perspective h3:not(.intro),
.content-sections .approach-perspective p a {
  color: #FECC02; }

.content-sections .approach-perspective img.full-width {
  margin-top: 150px; }

.content-sections .approach-perspective img.full-width.no-top-margin {
  margin-top: 0; }

.content-sections .case-studies .header {
  background-color: #4A90E2; }

.content-sections .section-storytelling .header {
  background-color: #6BA500; }

.content-sections .section-storytelling video {
  width: 100%; }

.content-sections .section-storytelling img.full-width {
  margin-top: 82px; }

.content-sections .section-storytelling img.full-width.no-top-margin {
  margin-top: 0; }

.content-sections .section-storytelling h3:not(.intro):not(.white-txt) {
  color: #6BA500; }

.content-sections .section-storytelling .marketing-competencies {
  width: calc(100% + 50px);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.content-sections .section-storytelling .marketing-competencies h4 {
  font-size: 24px;
  line-height: 1.33;
  color: #6BA500; }

.content-sections .section-approach .header {
  background-color: #00ADCD; }

.content-sections .section-approach video {
  width: 100%; }

.content-sections .section-approach img.full-width {
  margin-top: 82px; }

.content-sections .section-approach img.full-width.no-top-margin {
  margin-top: 0; }

.content-sections .section-approach h3:not(.intro):not(.white-txt) {
  color: #00ADCD; }

.content-sections .section-approach .marketing-competencies {
  width: calc(100% + 50px);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.content-sections .section-approach .marketing-competencies h4 {
  font-size: 24px;
  line-height: 1.33;
  color: #00ADCD; }

#introduction-bg {
  background-image: url("/public/images/hooray-science-bg.jpg");
  background-size: cover;
  background-position: right;
  min-height: 900px; }
  #introduction-bg p {
    max-width: 960px; }

#naming-1-bg {
  background-image: url("/public/images/Naming_1.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 800px; }

#naming-2-bg {
  background-image: url("/public/images/Naming_2.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 800px; }

#naming-3-bg {
  background-image: url("/public/images/Naming_3.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 800px; }

#storytelling-bg {
  background-image: url("/public/images/brain_halftone.jpg");
  background-size: cover;
  background-position: center;
  min-height: 800px; }

#our-approach-bg {
  background-image: url("/public/images/rover_hero.jpg");
  background-size: cover;
  background-position: center;
  min-height: 800px; }

#coaster-video {
  margin-bottom: -8px; }
  #coaster-video video {
    width: 100%; }

@media all and (max-width: 1023px) {
  .gallup-img {
    max-width: 50%; } }

.section-our-approach .accordion .heading h4 {
  color: #F1B500; }

.section-our-approach .accordion .icon {
  background-color: #F1B500; }

.section-our-approach .side-by-side {
  display: flex;
  flex-wrap: wrap; }
  .section-our-approach .side-by-side .card {
    border-color: #F1B500;
    width: 100%; }
    @media screen and (min-width: 1024px) {
      .section-our-approach .side-by-side .card {
        padding: 37px;
        width: 45%; } }
    .section-our-approach .side-by-side .card h5 {
      text-align: center; }

#ux-bg {
  background-image: url("/public/images/ux-bg.jpg");
  background-size: cover;
  background-position: center;
  min-height: 800px; }

#experience-bg {
  background-image: url("/public/images/ux-bg.jpg");
  background-size: cover;
  background-position: center;
  min-height: 800px; }

.section-our-recommendation .accordion .icon {
  background-color: #EE4A00; }

.section-our-recommendation .card {
  border-color: #EE4A00; }
  .section-our-recommendation .card.fill {
    background-color: #EE4A00; }

.section-our-recommendation .row-cards .card:after {
  background-color: transparent;
  background-image: url("/public/images/arrow-orange.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  width: 11px;
  right: -10px;
  border: none;
  height: 30px;
  transform: rotate(-90deg);
  z-index: 5; }

#our-recommendation-bg {
  background-image: url("/public/images/mike-wyatt-bg.jpg");
  background-size: cover;
  background-position: center; }

.section-recap {
  min-height: 700px; }
  @media screen and (min-width: 768px) {
    .section-recap {
      min-height: 1024px; } }
  @media screen and (min-width: 960px) {
    .section-recap {
      min-height: 100vh; } }
  .section-recap h1 {
    font-family: "clarendon-urw", serif;
    font-style: normal;
    font-weight: 100; }
    @media screen and (min-width: 768px) {
      .section-recap h1 {
        font-size: 120px; } }

#tech-bg {
  background-image: url("/public/images/tech-bg.jpg");
  background-size: cover;
  background-position: center; }

#time-budget-bg {
  background-image: url("/public/images/time-budget-bg.jpg");
  background-size: cover;
  background-position: center; }

.section-time-budget .table .cell span:not(.nbsp):not(.mobile-space) {
  color: #9013FE; }

.section-time-budget .time-line .card {
  border-color: #9013FE; }
  .section-time-budget .time-line .card span {
    color: #9013FE; }
  .section-time-budget .time-line .card:before {
    background-color: #9013FE; }
  .section-time-budget .time-line .card:after {
    background-color: transparent;
    background-image: url("/public/images/arrow-purple.svg");
    background-repeat: no-repeat;
    background-position: bottom; }

#paradowski-bg {
  background-image: url("/public/images/paradowski-bg.jpg");
  background-size: cover;
  background-position: center; }

.paradowski .team-members .image:after {
  background-color: #00ADCD; }

#dots-one,
#dots-two,
#dots-three,
#dots-four {
  background-image: url("/public/images/dots.jpg");
  background-color: #FFF;
  background-size: cover;
  background-position: center; }
  @media screen and (min-width: 768px) {
    #dots-one,
    #dots-two,
    #dots-three,
    #dots-four {
      min-height: 1024px; } }
  @media screen and (min-width: 960px) {
    #dots-one,
    #dots-two,
    #dots-three,
    #dots-four {
      min-height: 75vh; } }
  #dots-one .content-width,
  #dots-two .content-width,
  #dots-three .content-width,
  #dots-four .content-width {
    background: #fff;
    color: #EE4A00; }
    @media screen and (min-width: 768px) {
      #dots-one .content-width,
      #dots-two .content-width,
      #dots-three .content-width,
      #dots-four .content-width {
        padding: 2rem 10%; } }

#dots-one {
  background-image: url("/public/images/dots-redorange.jpg");
  background-position: center; }

#dots-two,
#dots-three {
  background-image: url("/public/images/dots-green.jpg");
  background-position: center; }

#dots-four {
  background-image: url("/public/images/dots-yellow.jpg");
  background-position: center; }

#astronaut {
  background-image: url("/public/images/atomic.png");
  background-size: cover;
  background-position: center;
  min-height: 400px; }
  @media screen and (min-width: 960px) {
    #astronaut {
      min-height: 50vh; } }
  #astronaut p.small {
    margin-top: 3rem;
    font-size: 21px;
    line-height: 1.4; }

#menu {
  background-image: url("/public/images/menus.jpg");
  background-size: cover;
  background-position: center;
  min-height: 536px; }
  @media screen and (min-width: 960px) {
    #menu {
      min-height: 800px; } }

#look-like {
  background-image: url("/public/images/look-like.jpg");
  background-size: cover;
  background-position: center;
  min-height: 690px; }
  @media screen and (min-width: 768px) {
    #look-like {
      min-height: 1024px; } }
  @media screen and (min-width: 960px) {
    #look-like {
      min-height: 700px; } }
  #look-like p.small {
    margin-top: 3rem;
    font-size: 21px;
    line-height: 1.4; }

#storytelling {
  background-color: #6BA500 !important; }

.section-storytelling .accordion .heading h4 {
  color: #6BA500; }

.section-storytelling .accordion .icon {
  background-color: #6BA500; }

.section-storytelling .modals .modal .close {
  background-color: #6BA500; }

.section-time-budget .accordion .heading h4 {
  color: #9013FE;
  font-weight: 400; }

.section-time-budget .accordion .icon {
  background-color: #9013FE; }

footer {
  display: flex;
  align-items: center;
  color: #fff;
  padding: 3rem 0;
  min-height: 700px; }
  @media screen and (min-width: 768px) {
    footer {
      min-height: 1024px; } }
  @media screen and (min-width: 960px) {
    footer {
      min-height: 100vh; } }
  footer h2 {
    font-family: "clarendon-urw", serif;
    font-style: normal;
    font-weight: 100; }
  footer .footer-columns {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    footer .footer-columns .col-one {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0 0 0 0;
      width: 100%; }
      @media all and (max-width: 1023px) {
        footer .footer-columns .col-one p {
          padding: 0 !important; } }
      @media all and (min-width: 1024px) {
        footer .footer-columns .col-one {
          align-items: center;
          justify-content: center;
          width: 80%;
          padding: 0 9% 0 0;
          flex: 2; } }
    footer .footer-columns .col-two {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      padding: 4rem 0 3rem; }
      @media all and (max-width: 767px) {
        footer .footer-columns .col-two {
          width: 90%; } }
      @media all and (min-width: 1024px) {
        footer .footer-columns .col-two {
          padding: 0;
          width: 20%;
          flex: 1; } }
      footer .footer-columns .col-two .address img {
        max-width: 165px; }
  footer .social {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2rem; }
    footer .social a {
      color: #fff;
      padding: 0 36px 0 0; }
      footer .social a i {
        font-size: 38px; }
  footer .download .btn {
    color: #000;
    background-color: #fff;
    text-decoration: none;
    width: auto;
    border-radius: 7px;
    padding: 5px 22px;
    margin: 2rem 0; }
  footer .download .nav-download {
    width: 20px;
    margin: 0 0 0 16px; }
  footer .contact {
    padding: 1rem 0; }
    footer .contact h5 {
      margin: 0;
      letter-spacing: .4rem; }

.link-to-pdf-section {
  display: flex;
  flex-wrap: wrap; }
  .link-to-pdf-section p {
    flex: 2; }
  .link-to-pdf-section .download .btn {
    color: #fff;
    background-color: #6BA500;
    text-decoration: none;
    width: auto;
    border-radius: 7px;
    padding: 5px 22px;
    margin: 2rem 0; }
  .link-to-pdf-section .download .nav-download {
    width: 20px;
    margin: 0 0 0 16px; }

#magic-scroll-1,
#magic-scroll-2,
#magic-scroll-3,
#magic-scroll-4 {
  position: relative; }

#magic-scroll-4 {
  display: inline-block; }

.main-nav {
  max-width: 100%;
  overflow: hidden; }
  @media all and (max-width: 767px) {
    .main-nav {
      margin-top: 0;
      position: fixed !important;
      top: -1px;
      left: 0;
      right: 0;
      z-index: 9999;
      width: 100%;
      padding: 0; }
      .main-nav span {
        display: none; }
      .main-nav ul {
        display: flex;
        flex-direction: row;
        height: 50px; }
        .main-nav ul li {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-items: center;
          text-align: center;
          width: calc(100vw/8);
          color: white; }
          .main-nav ul li a {
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-items: center;
            width: 100%;
            height: 100%;
            padding-top: 18px;
            text-decoration: none; }
          .main-nav ul li i {
            font-size: 13px; }
          .main-nav ul li.logo {
            width: auto;
            height: auto;
            background-color: transparent;
            cursor: default;
            color: white; }
          .main-nav ul li.to-top {
            opacity: 1;
            transition: transform 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 75ms, opacity 0.25s ease; }
            .main-nav ul li.to-top.visible {
              opacity: 1; }
            .main-nav ul li.to-top img {
              width: 18px; }
          .main-nav ul li.download a {
            color: #000; }
          .main-nav ul li.download .nav-download {
            width: 13px; } }
  @media all and (min-width: 768px) {
    .main-nav {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 95%;
      margin-top: 0;
      position: fixed;
      top: 0;
      z-index: 9999;
      pointer-events: none;
      left: 0;
      background-color: transparent;
      width: auto;
      padding: 0; }
      .main-nav * {
        pointer-events: all; }
      .main-nav.fixed {
        position: fixed;
        top: 0;
        left: 0; }
      .main-nav ul {
        display: flex;
        justify-content: center;
        flex-direction: column;
        transform: translateX(-91%);
        height: auto; }
        .main-nav ul li {
          font-family: 'Alte Haas Grotesk', 'sans-serif';
          font-style: normal;
          font-weight: 400;
          transform: translateX(0);
          display: flex;
          align-self: flex-end;
          justify-content: center;
          align-items: center;
          color: white;
          overflow: visible;
          text-align: center;
          height: 35px;
          box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
          height: 30px;
          padding: 0;
          margin: 0;
          border-left: 0;
          transition: transform 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 75ms;
          float: left;
          clear: left; }
          .main-nav ul li a {
            color: #fff;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding-right: 6px;
            padding-left: 50px;
            text-decoration: none; }
          .main-nav ul li i {
            font-size: 13px; }
          .main-nav ul li span {
            display: inline-block;
            font-size: 14px;
            padding: 0 16px 0 24px; }
          .main-nav ul li:hover, .main-nav ul li.active {
            transform: translateX(75%);
            cursor: pointer; }
          .main-nav ul li.logo {
            width: auto;
            height: auto;
            background-color: transparent;
            cursor: default; }
          .main-nav ul li.to-top {
            opacity: 0;
            transition: transform 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 75ms, opacity 0.25s ease; }
            .main-nav ul li.to-top.visible {
              opacity: 1; }
            .main-nav ul li.to-top img {
              width: 18px;
              margin-right: -2px; }
          .main-nav ul li.download a {
            color: #000; }
          .main-nav ul li.download .nav-download {
            width: 13px; } }

.trigger {
  height: 1px;
  width: 100%;
  background-color: none; }

.main-header {
  background-color: #000;
  width: 100%;
  height: auto;
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }
  .main-header #mbg-video {
    position: relative; }
  .main-header .header-animation {
    position: relative;
    overflow: hidden;
    width: 100%; }
    @media all and (max-width: 959px) and (orientation: landscape) {
      .main-header .header-animation {
        height: 100vw; } }
    @media all and (min-width: 960px) and (orientation: landscape) {
      .main-header .header-animation {
        height: 100vh; } }
    @media all and (max-width: 767px) and (orientation: portrait) {
      .main-header .header-animation {
        height: 72vw; } }
    @media all and (min-width: 768px) and (orientation: portrait) {
      .main-header .header-animation {
        height: 64vw; } }
    @media all and (min-width: 960px) and (orientation: portrait) {
      .main-header .header-animation {
        height: 100vw; } }
    .main-header .header-animation .layer {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      pointer-events: none;
      backface-visibility: hidden; }
      .main-header .header-animation .layer img {
        width: 100%;
        height: auto;
        backface-visibility: hidden;
        opacity: 0;
        transition: none !important; }
      @media all and (min-width: 1025px) {
        .main-header .header-animation .layer {
          top: 0%; } }
    .main-header .header-animation .item {
      position: absolute; }
      .main-header .header-animation .item img {
        width: 100%;
        height: auto; }
      .main-header .header-animation .item.bcs-vid {
        top: 60px;
        right: 0;
        width: 100%; }
        @media all and (min-width: 1025px) {
          .main-header .header-animation .item.bcs-vid {
            top: 0%; } }

.pc-info-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 700px;
  position: relative; }
  .pc-info-logo img {
    max-width: 644px;
    padding-bottom: 5rem;
    width: 89%; }
    @media screen and (min-width: 768px) {
      .pc-info-logo img {
        width: 84%; } }

.pc-info-logo-round {
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 700px; }
  .pc-info-logo-round img {
    margin-top: -199px;
    max-width: 89px;
    margin-left: -6px; }

#logo-round {
  top: 0vh;
  position: absolute; }
  #logo-round.fixed {
    top: 49vh;
    position: fixed; }

.intro-info-logo {
  min-height: 700px;
  height: 100vh;
  display: flex;
  align-items: center; }

.intro-info {
  display: flex;
  align-items: center;
  min-height: 700px;
  background-color: #000;
  color: #fff; }
  .intro-info:after {
    content: '';
    min-height: inherit;
    font-size: 0;
    display: block; }
  @media screen and (min-width: 960px) {
    .intro-info {
      min-height: 100vh; } }
  .intro-info .date {
    font-family: 'Alte Haas Grotesk', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: .3rem;
    margin-bottom: 53px; }
  .intro-info h1 {
    margin-bottom: 0px; }
  .intro-info h5 {
    font-size: 38px;
    font-family: "clarendon-urw", serif;
    font-style: normal;
    font-weight: 100;
    font-weight: 100;
    margin: 20px 0 14px; }
  .intro-info img {
    height: auto;
    max-width: 90%;
    vertical-align: bottom;
    margin-top: 67px; }
    .intro-info img.dan-logo {
      width: 89%;
      max-width: 163px;
      margin: 40px 0; }
      @media screen and (min-width: 768px) {
        .intro-info img.dan-logo {
          width: 35%; } }
    .intro-info img.sal-logo {
      width: 130px; }
    .intro-info img.mir-logo {
      width: 250px; }
    .intro-info img.wash-u-logo {
      width: 570px; }

.single-case-study {
  min-height: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
  background-position: center;
  position: relative; }
  .single-case-study:after {
    content: '';
    min-height: inherit;
    font-size: 0;
    display: block; }
  @media screen and (min-width: 960px) {
    .single-case-study {
      min-height: 100vh; } }
  .single-case-study .play-btn {
    display: block;
    position: relative;
    top: 30vh;
    min-height: 250px;
    text-align: center;
    position: relative; }
    @media screen and (min-width: 960px) {
      .single-case-study .play-btn {
        min-height: 21vh; } }
    .single-case-study .play-btn img {
      height: 100%;
      width: auto; }
  .single-case-study .case-info {
    padding: 5%;
    color: #fff;
    position: relative;
    z-index: 1; }
    .single-case-study .case-info h3 {
      margin: 0;
      margin-bottom: .5rem;
      max-width: 900px;
      color: #fff !important; }
    .single-case-study .case-info h5 {
      font-size: 21px;
      line-height: 1.619;
      margin-bottom: 9px;
      margin-top: 10px;
      font-family: 'Alte Haas Grotesk', 'sans-serif';
      font-style: normal;
      font-weight: 400;
      color: #fff !important; }
    .single-case-study .case-info .learn-more {
      color: #ffffff;
      text-decoration: underline;
      font-family: 'Alte Haas Grotesk', 'sans-serif'; }
      .single-case-study .case-info .learn-more:before {
        content: url("/images/plus-circle.svg");
        width: 20px;
        height: 29px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 11px; }
  .single-case-study .bg-vid-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden; }
    .single-case-study .bg-vid-container .listBG {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      overflow: hidden; }

.modal-study {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  color: white;
  overflow: auto; }
  .modal-study.blue h3 {
    color: #0095E7; }
  .modal-study.blue .close {
    background-color: #0095E7; }
  .modal-study.green h3 {
    color: #34913A; }
  .modal-study.green .close {
    background-color: #34913A; }
  .modal-study.light-blue h3 {
    color: #75E0FF; }
  .modal-study.light-blue .close {
    background-color: #75E0FF; }
  .modal-study.orange h3 {
    color: #F76D27; }
  .modal-study.orange .close {
    background-color: #F76D27; }

.close {
  position: fixed;
  top: calc(50px + 102px);
  right: calc(50vw - 590px + 50px);
  width: 95px;
  height: 95px;
  border-radius: 50%;
  line-height: 85px;
  font-size: 76px;
  color: white;
  text-align: center;
  transform: rotate(-45deg);
  font-weight: normal; }

.modal-wrapper {
  margin: 102px auto;
  max-width: 1180px;
  width: 100%;
  padding: 125px 0;
  background-color: white;
  color: black;
  position: relative; }
  .modal-wrapper h3 {
    margin-bottom: 30px; }

.modal-video {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: black;
  color: white;
  overflow: auto; }
  .modal-video iframe {
    position: absolute;
    top: 6rem;
    left: 10%;
    width: 80%;
    height: 80%; }
  .modal-video .vid-title {
    margin: 0;
    position: absolute;
    top: 25px;
    left: 25px; }
  .modal-video .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    color: white; }
    .modal-video .close-btn:before {
      content: '+';
      width: 40px;
      height: 40px;
      background-color: #fff;
      border-radius: 50%;
      line-height: 35px;
      font-size: 46px;
      color: black;
      text-align: center;
      transform: rotate(-45deg);
      font-weight: normal;
      display: inline-block;
      vertical-align: middle;
      margin-right: 11px; }

.gradient {
  width: 100%;
  height: 100%;
  position: absolute; }

.naming-slides {
  background-color: transparent; }
  .naming-slides .naming-slide {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .naming-slides .naming-slide h3 {
      margin: 0;
      text-align: center; }
      .naming-slides .naming-slide h3:after {
        content: "";
        border-top: 1px solid rgba(255, 255, 255, 0.6);
        width: 100%;
        height: 1px;
        display: block;
        margin-top: 10px; }
    .naming-slides .naming-slide h5 {
      text-transform: uppercase;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.6);
      padding: 0 30px;
      margin: 0;
      padding: 3px 0; }
      .naming-slides .naming-slide h5:nth-of-type(1) {
        padding-bottom: 4rem; }
  .naming-slides .three-flex {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .naming-slides .three-flex:after {
      content: "";
      border-top: 1px solid rgba(255, 255, 255, 0.6);
      width: 100%;
      height: 1px;
      display: inline-block;
      margin-top: 15px; }
    .naming-slides .three-flex .box {
      background: #fff;
      padding: 30px 20px;
      flex: 1;
      margin: 1rem 0;
      justify-content: center;
      align-items: center;
      line-height: 1.2;
      font-size: 2rem;
      display: flex;
      flex-basis: 1; }
      @media screen and (min-width: 768px) {
        .naming-slides .three-flex .box {
          margin: 0;
          margin-right: 3%; } }
      .naming-slides .three-flex .box:last-of-type {
        margin-right: 0; }
      .naming-slides .three-flex .box p {
        text-align: center; }
  .naming-slides #naming-1-bg {
    color: #00ADCD; }
  .naming-slides #naming-2-bg {
    color: #6BA500; }
  .naming-slides #naming-3-bg {
    color: #F76D27; }

#mbg-logos-collateral .concept {
  background-size: cover;
  background-position: calc(50% - 1px);
  background-color: transparent;
  height: 60vw;
  width: 100%;
  min-height: 180px;
  padding: 0; }
  @media screen and (min-width: 768px) {
    #mbg-logos-collateral .concept {
      height: 100vh; }
      #mbg-logos-collateral .concept.fixed {
        background-attachment: fixed; } }
  #mbg-logos-collateral .concept.tb.c1.one {
    background-image: url("/public/images/logos-collateral/1_Cardinal/Divider_Concept1_Reference.jpg"); }
  #mbg-logos-collateral .concept.tb.c1.two {
    background-image: url("/public/images/logos-collateral/1_Cardinal/1_Concept1_Parent_logo.svg"); }
  #mbg-logos-collateral .concept.tb.c1.three {
    background-image: url("/public/images/logos-collateral/1_Cardinal/3_Concept1_Brochures.jpg"); }
  #mbg-logos-collateral .concept.tb.c1.four {
    background-image: url("/public/images/logos-collateral/1_Cardinal/4_Concept1_Logos.svg"); }
  #mbg-logos-collateral .concept.tb.c1.five {
    background-image: url("/public/images/logos-collateral/1_Cardinal/5_Concept1_Butterfly.jpg"); }
  #mbg-logos-collateral .concept.tb.c1.six {
    background-image: url("/public/images/logos-collateral/1_Cardinal/1_Concept1_Divider.jpg"); }
  #mbg-logos-collateral .concept.tb.c2.one {
    background-image: url("/public/images/logos-collateral/3_Glass/Divider_Concept3_Reference.jpg"); }
  #mbg-logos-collateral .concept.tb.c2.two {
    background-image: url("/public/images/logos-collateral/3_Glass/2_Concept3_Main_logo.svg"); }
  #mbg-logos-collateral .concept.tb.c2.three {
    background-image: url("/public/images/logos-collateral/3_Glass/3_Concept3_Brochures.jpg"); }
  #mbg-logos-collateral .concept.tb.c2.four {
    background-image: url("/public/images/logos-collateral/3_Glass/4_Concept3_Logos.svg"); }
  #mbg-logos-collateral .concept.tb.c2.five {
    background-image: url("/public/images/logos-collateral/3_Glass/5_Concept3_Butterfly.jpg"); }
  #mbg-logos-collateral .concept.tb.c2.six {
    background-image: url("/public/images/logos-collateral/3_Glass/1_Concept3_Divider.jpg"); }
  #mbg-logos-collateral .concept.cf.c1.one {
    background-image: url("/public/images/logos-collateral/2_Matisse/Divider_Concept2_Reference.jpg"); }
  #mbg-logos-collateral .concept.cf.c1.two {
    background-image: url("/public/images/logos-collateral/2_Matisse/1_Concept2_Parent_logo.svg"); }
  #mbg-logos-collateral .concept.cf.c1.three {
    background-image: url("/public/images/logos-collateral/2_Matisse/3_Concept2_Brochures.jpg"); }
  #mbg-logos-collateral .concept.cf.c1.four {
    background-image: url("/public/images/logos-collateral/2_Matisse/4_Concept2_Logos.svg"); }
  #mbg-logos-collateral .concept.cf.c1.five {
    background-image: url("/public/images/logos-collateral/2_Matisse/5_Concept2_Butterfly.jpg"); }
  #mbg-logos-collateral .concept.cf.c1.six {
    background-image: url("/public/images/logos-collateral/2_Matisse/1_Concept2_Divider.jpg"); }
  #mbg-logos-collateral .concept.cf.c2.one {
    background-image: url("/public/images/logos-collateral/4_Dome/Divider_Concept4_Reference.jpg"); }
  #mbg-logos-collateral .concept.cf.c2.two {
    background-image: url("/public/images/logos-collateral/4_Dome/2_Concept4_Main_logo.svg"); }
  #mbg-logos-collateral .concept.cf.c2.three {
    background-image: url("/public/images/logos-collateral/4_Dome/3_Concept4_Brochures.jpg"); }
  #mbg-logos-collateral .concept.cf.c2.four {
    background-image: url("/public/images/logos-collateral/4_Dome/4_Concept4_Logos.svg"); }
  #mbg-logos-collateral .concept.cf.c2.five {
    background-image: url("/public/images/logos-collateral/4_Dome/5_Concept4_Butterfly.jpg"); }
  #mbg-logos-collateral .concept.cf.c2.six {
    background-image: url("/public/images/logos-collateral/4_Dome/1_Concept2_Divider.jpg"); }

section.our-perspective .h3-alte {
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-weight: bold; }

section.our-perspective .uppercase-wide-spacing {
  max-width: 92.5%;
  font-weight: 700; }
  @media screen and (min-width: 375px) {
    section.our-perspective .uppercase-wide-spacing {
      max-width: 82.5%; } }

section.our-perspective .flex {
  display: flex;
  flex: 1;
  justify-content: space-between; }
  section.our-perspective .flex.rows {
    flex-direction: row;
    flex: 1 1 0; }
    @media screen and (min-width: 768px) {
      section.our-perspective .flex.rows-tablet {
        flex-direction: row;
        flex: 1 1 0; } }
  section.our-perspective .flex.row {
    flex-basis: 100%; }
    @media screen and (min-width: 768px) {
      section.our-perspective .flex.row-tablet {
        flex-basis: 100%; } }
  section.our-perspective .flex.columns {
    flex-direction: column; }
    @media screen and (min-width: 768px) {
      section.our-perspective .flex.columns-tablet {
        flex-direction: column; } }
  section.our-perspective .flex.column {
    flex-basis: auto; }
    @media screen and (min-width: 768px) {
      section.our-perspective .flex.column-tablet {
        flex-basis: auto; } }
  @media screen and (min-width: 768px) {
    section.our-perspective .flex.rows-tablet {
      flex-direction: row;
      flex: 1 1 0; } }
  @media screen and (min-width: 768px) {
    section.our-perspective .flex.row-tablet {
      flex-basis: 100%; } }

section.our-perspective .org-chart + .org-chart {
  margin-top: 86px; }
  @media screen and (min-width: 768px) {
    section.our-perspective .org-chart + .org-chart {
      margin-top: 0;
      margin-left: 5%; } }

section.our-perspective .nodes {
  position: relative; }
  section.our-perspective .nodes:after {
    content: '';
    position: absolute;
    background: #979797;
    height: 52px;
    width: 1px;
    left: 50%;
    top: 11px; }
  section.our-perspective .nodes .node {
    background: #f76d27;
    margin-top: 117px; }
    section.our-perspective .nodes .node:before, section.our-perspective .nodes .node:after {
      content: '';
      position: absolute;
      background: #979797; }
    section.our-perspective .nodes .node:before {
      height: 41px;
      width: 1px;
      left: 50%;
      bottom: calc(100% + 13px); }
    section.our-perspective .nodes .node:after {
      width: 100%;
      height: 1px;
      left: 50%;
      bottom: calc(100% + 54px); }
    section.our-perspective .nodes .node + .node:after {
      left: auto;
      right: 50%; }
    section.our-perspective .nodes .node .uppercase-wide-spacing {
      letter-spacing: 3.3px; }

section.our-perspective .node {
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 164px;
  background: #6BA500; }
  section.our-perspective .node + .node {
    margin-left: 38px; }

section.section-our-approach h4.uppercase-wide-spacing {
  letter-spacing: 1.7px;
  text-align: left;
  margin-bottom: 30px; }

section.tech .tech-stack h4, section.tech .tech-stack p {
  line-height: 28px; }

section.tech .tech-stack h4 {
  color: #D96392;
  font-size: 24px; }

section.tech .tech-stack p {
  font-size: 16px; }

section.paradowski p a {
  color: black; }

section.paradowski .clients h4, section.paradowski .clients p {
  line-height: 28px; }

section.paradowski .clients h4 {
  font-size: 24px; }

section.paradowski .clients p {
  font-size: 16px; }

section.paradowski .clients a {
  text-decoration: none;
  font-weight: bold; }

section.paradowski .placeholder {
  margin-bottom: 0; }

section.paradowski a.btn {
  text-decoration: none; }

section.section-our-recommendation .contained {
  max-width: 430px; }

@media screen and (min-width: 768px) {
  .introduction h5 {
    margin-top: 82px; } }

@media screen and (min-width: 768px) {
  .introduction .styled-list li {
    padding-bottom: 0;
    margin-bottom: calc(21px * 1.619); } }
